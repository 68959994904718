@charset "UTF-8";
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden; }

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img,
ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody,
tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time,
mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

html {
  overflow-x: hidden; }

body {
  line-height: 1;
  overflow-x: hidden; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

ol, ul {
  list-style: none; }

strong, b {
  font-weight: bold; }

input:focus {
  outline: none; }

blockquote, q {
  quotes: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a[ng-click], div[ng-click], p[ng-click], span[ng-click], img[ng-click], a[ui-sref], div[ui-sref], p[ui-sref], span[ui-sref], img[ui-sref] {
  cursor: pointer; }

textarea:hover, input:hover, textarea:active, input:active, textarea:focus, input:focus, button:focus, button:active, button:hover, label:focus, .btn:active, .btn.active {
  outline: 0px !important;
  -webkit-appearance: none; }

.slick-slide, .slick-slide a, .slick-slide:focus {
  outline: none !important; }

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill, textarea:-webkit-autofill,
input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
          animation: autofill 0s forwards; }

@keyframes autofill {
  100% {
    background: #fff;
    color: inherit; } }

@-webkit-keyframes autofill {
  100% {
    background: rgba(0, 0, 0, 0.1);
    color: inherit; } }

input:-webkit-autofill {
  background-color: #fff !important; }

a {
  -webkit-tap-highlight-color: transparent; }

@font-face {
  font-family: 'Akkurat-Light';
  src: url("../fonts/Akkurat-Light.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Akkurat-Regular';
  src: url("../fonts/Akkurat-Normal.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'NoeDisplay-Regular';
  src: url("../fonts/noe-display-regular.ttf");
  font-weight: normal;
  font-style: normal; }

.row.fixPadding {
  width: calc(100% + 1% + 1%);
  margin-left: -1%;
  margin-right: -1%; }

.row:after {
  content: '';
  display: block;
  clear: both; }

.col1 {
  width: 4.16667%; }

.col2 {
  width: 8.33333%; }

.col3 {
  width: 12.5%; }

.col4 {
  width: 16.66667%; }

.col5 {
  width: 20.83333%; }

.col6 {
  width: 25%; }

.col7 {
  width: 29.16667%; }

.col8 {
  width: 33.33333%; }

.col9 {
  width: 37.5%; }

.col10 {
  width: 41.66667%; }

.col11 {
  width: 45.83333%; }

.col12 {
  width: 50%; }

.col13 {
  width: 54.16667%; }

.col14 {
  width: 58.33333%; }

.col15 {
  width: 62.5%; }

.col16 {
  width: 66.66667%; }

.col17 {
  width: 70.83333%; }

.col18 {
  width: 75%; }

.col19 {
  width: 79.16667%; }

.col20 {
  width: 83.33333%; }

.col21 {
  width: 87.5%; }

.col22 {
  width: 91.66667%; }

.col23 {
  width: 95.83333%; }

.col24 {
  width: 100%; }

.col, .col1, .col2, .col3, .col4, .col5, .col6, .col7, .col8, .col9, .col10, .col11, .col12,
.col13, .col14, .col15, .col16, .col17, .col18, .col19, .col20, .col21, .col22, .col23, .col24 {
  display: block;
  float: left;
  min-height: 1px;
  padding: 0 1%; }

@media screen and (max-width: 1800px) {
  .col1_1800 {
    width: 4.16667%; }
  .col2_1800 {
    width: 8.33333%; }
  .col3_1800 {
    width: 12.5%; }
  .col4_1800 {
    width: 16.66667%; }
  .col5_1800 {
    width: 20.83333%; }
  .col6_1800 {
    width: 25%; }
  .col7_1800 {
    width: 29.16667%; }
  .col8_1800 {
    width: 33.33333%; }
  .col9_1800 {
    width: 37.5%; }
  .col10_1800 {
    width: 41.66667%; }
  .col11_1800 {
    width: 45.83333%; }
  .col12_1800 {
    width: 50%; }
  .col13_1800 {
    width: 54.16667%; }
  .col14_1800 {
    width: 58.33333%; }
  .col15_1800 {
    width: 62.5%; }
  .col16_1800 {
    width: 66.66667%; }
  .col17_1800 {
    width: 70.83333%; }
  .col18_1800 {
    width: 75%; }
  .col19_1800 {
    width: 79.16667%; }
  .col20_1800 {
    width: 83.33333%; }
  .col21_1800 {
    width: 87.5%; }
  .col22_1800 {
    width: 91.66667%; }
  .col23_1800 {
    width: 95.83333%; }
  .col24_1800 {
    width: 100%; }
  .col1_1800, .col2_1800, .col3_1800, .col4_1800, .col5_1800, .col6_1800,
  .col7_1800, .col8_1800, .col9_1800, .col10_1800, .col11_1800, .col12_1800,
  .col13_1800, .col14_1800, .col15_1800, .col16_1800, .col17_1800, .col18_1800,
  .col19_1800, .col20_1800, .col21_1800, .col22_1800, .col23_1800, .col24_1800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1700px) {
  .col1_1700 {
    width: 4.16667%; }
  .col2_1700 {
    width: 8.33333%; }
  .col3_1700 {
    width: 12.5%; }
  .col4_1700 {
    width: 16.66667%; }
  .col5_1700 {
    width: 20.83333%; }
  .col6_1700 {
    width: 25%; }
  .col7_1700 {
    width: 29.16667%; }
  .col8_1700 {
    width: 33.33333%; }
  .col9_1700 {
    width: 37.5%; }
  .col10_1700 {
    width: 41.66667%; }
  .col11_1700 {
    width: 45.83333%; }
  .col12_1700 {
    width: 50%; }
  .col13_1700 {
    width: 54.16667%; }
  .col14_1700 {
    width: 58.33333%; }
  .col15_1700 {
    width: 62.5%; }
  .col16_1700 {
    width: 66.66667%; }
  .col17_1700 {
    width: 70.83333%; }
  .col18_1700 {
    width: 75%; }
  .col19_1700 {
    width: 79.16667%; }
  .col20_1700 {
    width: 83.33333%; }
  .col21_1700 {
    width: 87.5%; }
  .col22_1700 {
    width: 91.66667%; }
  .col23_1700 {
    width: 95.83333%; }
  .col24_1700 {
    width: 100%; }
  .col1_1700, .col2_1700, .col3_1700, .col4_1700, .col5_1700, .col6_1700,
  .col7_1700, .col8_1700, .col9_1700, .col10_1700, .col11_1700, .col12_1700,
  .col13_1700, .col14_1700, .col15_1700, .col16_1700, .col17_1700, .col18_1700,
  .col19_1700, .col20_1700, .col21_1700, .col22_1700, .col23_1700, .col24_1700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1600px) {
  .col1_1600 {
    width: 4.16667%; }
  .col2_1600 {
    width: 8.33333%; }
  .col3_1600 {
    width: 12.5%; }
  .col4_1600 {
    width: 16.66667%; }
  .col5_1600 {
    width: 20.83333%; }
  .col6_1600 {
    width: 25%; }
  .col7_1600 {
    width: 29.16667%; }
  .col8_1600 {
    width: 33.33333%; }
  .col9_1600 {
    width: 37.5%; }
  .col10_1600 {
    width: 41.66667%; }
  .col11_1600 {
    width: 45.83333%; }
  .col12_1600 {
    width: 50%; }
  .col13_1600 {
    width: 54.16667%; }
  .col14_1600 {
    width: 58.33333%; }
  .col15_1600 {
    width: 62.5%; }
  .col16_1600 {
    width: 66.66667%; }
  .col17_1600 {
    width: 70.83333%; }
  .col18_1600 {
    width: 75%; }
  .col19_1600 {
    width: 79.16667%; }
  .col20_1600 {
    width: 83.33333%; }
  .col21_1600 {
    width: 87.5%; }
  .col22_1600 {
    width: 91.66667%; }
  .col23_1600 {
    width: 95.83333%; }
  .col24_1600 {
    width: 100%; }
  .col1_1600, .col2_1600, .col3_1600, .col4_1600, .col5_1600, .col6_1600,
  .col7_1600, .col8_1600, .col9_1600, .col10_1600, .col11_1600, .col12_1600,
  .col13_1600, .col14_1600, .col15_1600, .col16_1600, .col17_1600, .col18_1600,
  .col19_1600, .col20_1600, .col21_1600, .col22_1600, .col23_1600, .col24_1600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1500px) {
  .col1_1500 {
    width: 4.16667%; }
  .col2_1500 {
    width: 8.33333%; }
  .col3_1500 {
    width: 12.5%; }
  .col4_1500 {
    width: 16.66667%; }
  .col5_1500 {
    width: 20.83333%; }
  .col6_1500 {
    width: 25%; }
  .col7_1500 {
    width: 29.16667%; }
  .col8_1500 {
    width: 33.33333%; }
  .col9_1500 {
    width: 37.5%; }
  .col10_1500 {
    width: 41.66667%; }
  .col11_1500 {
    width: 45.83333%; }
  .col12_1500 {
    width: 50%; }
  .col13_1500 {
    width: 54.16667%; }
  .col14_1500 {
    width: 58.33333%; }
  .col15_1500 {
    width: 62.5%; }
  .col16_1500 {
    width: 66.66667%; }
  .col17_1500 {
    width: 70.83333%; }
  .col18_1500 {
    width: 75%; }
  .col19_1500 {
    width: 79.16667%; }
  .col20_1500 {
    width: 83.33333%; }
  .col21_1500 {
    width: 87.5%; }
  .col22_1500 {
    width: 91.66667%; }
  .col23_1500 {
    width: 95.83333%; }
  .col24_1500 {
    width: 100%; }
  .col1_1500, .col2_1500, .col3_1500, .col4_1500, .col5_1500, .col6_1500,
  .col7_1500, .col8_1500, .col9_1500, .col10_1500, .col11_1500, .col12_1500,
  .col13_1500, .col14_1500, .col15_1500, .col16_1500, .col17_1500, .col18_1500,
  .col19_1500, .col20_1500, .col21_1500, .col22_1500, .col23_1500, .col24_1500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1400px) {
  .col1_1400 {
    width: 4.16667%; }
  .col2_1400 {
    width: 8.33333%; }
  .col3_1400 {
    width: 12.5%; }
  .col4_1400 {
    width: 16.66667%; }
  .col5_1400 {
    width: 20.83333%; }
  .col6_1400 {
    width: 25%; }
  .col7_1400 {
    width: 29.16667%; }
  .col8_1400 {
    width: 33.33333%; }
  .col9_1400 {
    width: 37.5%; }
  .col10_1400 {
    width: 41.66667%; }
  .col11_1400 {
    width: 45.83333%; }
  .col12_1400 {
    width: 50%; }
  .col13_1400 {
    width: 54.16667%; }
  .col14_1400 {
    width: 58.33333%; }
  .col15_1400 {
    width: 62.5%; }
  .col16_1400 {
    width: 66.66667%; }
  .col17_1400 {
    width: 70.83333%; }
  .col18_1400 {
    width: 75%; }
  .col19_1400 {
    width: 79.16667%; }
  .col20_1400 {
    width: 83.33333%; }
  .col21_1400 {
    width: 87.5%; }
  .col22_1400 {
    width: 91.66667%; }
  .col23_1400 {
    width: 95.83333%; }
  .col24_1400 {
    width: 100%; }
  .col1_1400, .col2_1400, .col3_1400, .col4_1400, .col5_1400, .col6_1400,
  .col7_1400, .col8_1400, .col9_1400, .col10_1400, .col11_1400, .col12_1400,
  .col13_1400, .col14_1400, .col15_1400, .col16_1400, .col17_1400, .col18_1400,
  .col19_1400, .col20_1400, .col21_1400, .col22_1400, .col23_1400, .col24_1400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1300px) {
  .col1_1300 {
    width: 4.16667%; }
  .col2_1300 {
    width: 8.33333%; }
  .col3_1300 {
    width: 12.5%; }
  .col4_1300 {
    width: 16.66667%; }
  .col5_1300 {
    width: 20.83333%; }
  .col6_1300 {
    width: 25%; }
  .col7_1300 {
    width: 29.16667%; }
  .col8_1300 {
    width: 33.33333%; }
  .col9_1300 {
    width: 37.5%; }
  .col10_1300 {
    width: 41.66667%; }
  .col11_1300 {
    width: 45.83333%; }
  .col12_1300 {
    width: 50%; }
  .col13_1300 {
    width: 54.16667%; }
  .col14_1300 {
    width: 58.33333%; }
  .col15_1300 {
    width: 62.5%; }
  .col16_1300 {
    width: 66.66667%; }
  .col17_1300 {
    width: 70.83333%; }
  .col18_1300 {
    width: 75%; }
  .col19_1300 {
    width: 79.16667%; }
  .col20_1300 {
    width: 83.33333%; }
  .col21_1300 {
    width: 87.5%; }
  .col22_1300 {
    width: 91.66667%; }
  .col23_1300 {
    width: 95.83333%; }
  .col24_1300 {
    width: 100%; }
  .col1_1300, .col2_1300, .col3_1300, .col4_1300, .col5_1300, .col6_1300,
  .col7_1300, .col8_1300, .col9_1300, .col10_1300, .col11_1300, .col12_1300,
  .col13_1300, .col14_1300, .col15_1300, .col16_1300, .col17_1300, .col18_1300,
  .col19_1300, .col20_1300, .col21_1300, .col22_1300, .col23_1300, .col24_1300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1200px) {
  .col1_1200 {
    width: 4.16667%; }
  .col2_1200 {
    width: 8.33333%; }
  .col3_1200 {
    width: 12.5%; }
  .col4_1200 {
    width: 16.66667%; }
  .col5_1200 {
    width: 20.83333%; }
  .col6_1200 {
    width: 25%; }
  .col7_1200 {
    width: 29.16667%; }
  .col8_1200 {
    width: 33.33333%; }
  .col9_1200 {
    width: 37.5%; }
  .col10_1200 {
    width: 41.66667%; }
  .col11_1200 {
    width: 45.83333%; }
  .col12_1200 {
    width: 50%; }
  .col13_1200 {
    width: 54.16667%; }
  .col14_1200 {
    width: 58.33333%; }
  .col15_1200 {
    width: 62.5%; }
  .col16_1200 {
    width: 66.66667%; }
  .col17_1200 {
    width: 70.83333%; }
  .col18_1200 {
    width: 75%; }
  .col19_1200 {
    width: 79.16667%; }
  .col20_1200 {
    width: 83.33333%; }
  .col21_1200 {
    width: 87.5%; }
  .col22_1200 {
    width: 91.66667%; }
  .col23_1200 {
    width: 95.83333%; }
  .col24_1200 {
    width: 100%; }
  .col1_1200, .col2_1200, .col3_1200, .col4_1200, .col5_1200, .col6_1200,
  .col7_1200, .col8_1200, .col9_1200, .col10_1200, .col11_1200, .col12_1200,
  .col13_1200, .col14_1200, .col15_1200, .col16_1200, .col17_1200, .col18_1200,
  .col19_1200, .col20_1200, .col21_1200, .col22_1200, .col23_1200, .col24_1200 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1100px) {
  .col1_1100 {
    width: 4.16667%; }
  .col2_1100 {
    width: 8.33333%; }
  .col3_1100 {
    width: 12.5%; }
  .col4_1100 {
    width: 16.66667%; }
  .col5_1100 {
    width: 20.83333%; }
  .col6_1100 {
    width: 25%; }
  .col7_1100 {
    width: 29.16667%; }
  .col8_1100 {
    width: 33.33333%; }
  .col9_1100 {
    width: 37.5%; }
  .col10_1100 {
    width: 41.66667%; }
  .col11_1100 {
    width: 45.83333%; }
  .col12_1100 {
    width: 50%; }
  .col13_1100 {
    width: 54.16667%; }
  .col14_1100 {
    width: 58.33333%; }
  .col15_1100 {
    width: 62.5%; }
  .col16_1100 {
    width: 66.66667%; }
  .col17_1100 {
    width: 70.83333%; }
  .col18_1100 {
    width: 75%; }
  .col19_1100 {
    width: 79.16667%; }
  .col20_1100 {
    width: 83.33333%; }
  .col21_1100 {
    width: 87.5%; }
  .col22_1100 {
    width: 91.66667%; }
  .col23_1100 {
    width: 95.83333%; }
  .col24_1100 {
    width: 100%; }
  .col1_1100, .col2_1100, .col3_1100, .col4_1100, .col5_1100, .col6_1100,
  .col7_1100, .col8_1100, .col9_1100, .col10_1100, .col11_1100, .col12_1100,
  .col13_1100, .col14_1100, .col15_1100, .col16_1100, .col17_1100, .col18_1100,
  .col19_1100, .col20_1100, .col21_1100, .col22_1100, .col23_1100, .col24_1100 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1024px) {
  .col1_1024 {
    width: 4.16667%; }
  .col2_1024 {
    width: 8.33333%; }
  .col3_1024 {
    width: 12.5%; }
  .col4_1024 {
    width: 16.66667%; }
  .col5_1024 {
    width: 20.83333%; }
  .col6_1024 {
    width: 25%; }
  .col7_1024 {
    width: 29.16667%; }
  .col8_1024 {
    width: 33.33333%; }
  .col9_1024 {
    width: 37.5%; }
  .col10_1024 {
    width: 41.66667%; }
  .col11_1024 {
    width: 45.83333%; }
  .col12_1024 {
    width: 50%; }
  .col13_1024 {
    width: 54.16667%; }
  .col14_1024 {
    width: 58.33333%; }
  .col15_1024 {
    width: 62.5%; }
  .col16_1024 {
    width: 66.66667%; }
  .col17_1024 {
    width: 70.83333%; }
  .col18_1024 {
    width: 75%; }
  .col19_1024 {
    width: 79.16667%; }
  .col20_1024 {
    width: 83.33333%; }
  .col21_1024 {
    width: 87.5%; }
  .col22_1024 {
    width: 91.66667%; }
  .col23_1024 {
    width: 95.83333%; }
  .col24_1024 {
    width: 100%; }
  .col1_1024, .col2_1024, .col3_1024, .col4_1024, .col5_1024, .col6_1024,
  .col7_1024, .col8_1024, .col9_1024, .col10_1024, .col11_1024, .col12_1024,
  .col13_1024, .col14_1024, .col15_1024, .col16_1024, .col17_1024, .col18_1024,
  .col19_1024, .col20_1024, .col21_1024, .col22_1024, .col23_1024, .col24_1024 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 1000px) {
  .col1_1000 {
    width: 4.16667%; }
  .col2_1000 {
    width: 8.33333%; }
  .col3_1000 {
    width: 12.5%; }
  .col4_1000 {
    width: 16.66667%; }
  .col5_1000 {
    width: 20.83333%; }
  .col6_1000 {
    width: 25%; }
  .col7_1000 {
    width: 29.16667%; }
  .col8_1000 {
    width: 33.33333%; }
  .col9_1000 {
    width: 37.5%; }
  .col10_1000 {
    width: 41.66667%; }
  .col11_1000 {
    width: 45.83333%; }
  .col12_1000 {
    width: 50%; }
  .col13_1000 {
    width: 54.16667%; }
  .col14_1000 {
    width: 58.33333%; }
  .col15_1000 {
    width: 62.5%; }
  .col16_1000 {
    width: 66.66667%; }
  .col17_1000 {
    width: 70.83333%; }
  .col18_1000 {
    width: 75%; }
  .col19_1000 {
    width: 79.16667%; }
  .col20_1000 {
    width: 83.33333%; }
  .col21_1000 {
    width: 87.5%; }
  .col22_1000 {
    width: 91.66667%; }
  .col23_1000 {
    width: 95.83333%; }
  .col24_1000 {
    width: 100%; }
  .col1_1000, .col2_1000, .col3_1000, .col4_1000, .col5_1000, .col6_1000,
  .col7_1000, .col8_1000, .col9_1000, .col10_1000, .col11_1000, .col12_1000,
  .col13_1000, .col14_1000, .col15_1000, .col16_1000, .col17_1000, .col18_1000,
  .col19_1000, .col20_1000, .col21_1000, .col22_1000, .col23_1000, .col24_1000 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 900px) {
  .col1_900 {
    width: 4.16667%; }
  .col2_900 {
    width: 8.33333%; }
  .col3_900 {
    width: 12.5%; }
  .col4_900 {
    width: 16.66667%; }
  .col5_900 {
    width: 20.83333%; }
  .col6_900 {
    width: 25%; }
  .col7_900 {
    width: 29.16667%; }
  .col8_900 {
    width: 33.33333%; }
  .col9_900 {
    width: 37.5%; }
  .col10_900 {
    width: 41.66667%; }
  .col11_900 {
    width: 45.83333%; }
  .col12_900 {
    width: 50%; }
  .col13_900 {
    width: 54.16667%; }
  .col14_900 {
    width: 58.33333%; }
  .col15_900 {
    width: 62.5%; }
  .col16_900 {
    width: 66.66667%; }
  .col17_900 {
    width: 70.83333%; }
  .col18_900 {
    width: 75%; }
  .col19_900 {
    width: 79.16667%; }
  .col20_900 {
    width: 83.33333%; }
  .col21_900 {
    width: 87.5%; }
  .col22_900 {
    width: 91.66667%; }
  .col23_900 {
    width: 95.83333%; }
  .col24_900 {
    width: 100%; }
  .col1_900, .col2_900, .col3_900, .col4_900, .col5_900, .col6_900,
  .col7_900, .col8_900, .col9_900, .col10_900, .col11_900, .col12_900,
  .col13_900, .col14_900, .col15_900, .col16_900, .col17_900, .col18_900,
  .col19_900, .col20_900, .col21_900, .col22_900, .col23_900, .col24_900 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 800px) {
  .col1_800 {
    width: 4.16667%; }
  .col2_800 {
    width: 8.33333%; }
  .col3_800 {
    width: 12.5%; }
  .col4_800 {
    width: 16.66667%; }
  .col5_800 {
    width: 20.83333%; }
  .col6_800 {
    width: 25%; }
  .col7_800 {
    width: 29.16667%; }
  .col8_800 {
    width: 33.33333%; }
  .col9_800 {
    width: 37.5%; }
  .col10_800 {
    width: 41.66667%; }
  .col11_800 {
    width: 45.83333%; }
  .col12_800 {
    width: 50%; }
  .col13_800 {
    width: 54.16667%; }
  .col14_800 {
    width: 58.33333%; }
  .col15_800 {
    width: 62.5%; }
  .col16_800 {
    width: 66.66667%; }
  .col17_800 {
    width: 70.83333%; }
  .col18_800 {
    width: 75%; }
  .col19_800 {
    width: 79.16667%; }
  .col20_800 {
    width: 83.33333%; }
  .col21_800 {
    width: 87.5%; }
  .col22_800 {
    width: 91.66667%; }
  .col23_800 {
    width: 95.83333%; }
  .col24_800 {
    width: 100%; }
  .col1_800, .col2_800, .col3_800, .col4_800, .col5_800, .col6_800,
  .col7_800, .col8_800, .col9_800, .col10_800, .col11_800, .col12_800,
  .col13_800, .col14_800, .col15_800, .col16_800, .col17_800, .col18_800,
  .col19_800, .col20_800, .col21_800, .col22_800, .col23_800, .col24_800 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 768px) {
  .col1_768 {
    width: 4.16667%; }
  .col2_768 {
    width: 8.33333%; }
  .col3_768 {
    width: 12.5%; }
  .col4_768 {
    width: 16.66667%; }
  .col5_768 {
    width: 20.83333%; }
  .col6_768 {
    width: 25%; }
  .col7_768 {
    width: 29.16667%; }
  .col8_768 {
    width: 33.33333%; }
  .col9_768 {
    width: 37.5%; }
  .col10_768 {
    width: 41.66667%; }
  .col11_768 {
    width: 45.83333%; }
  .col12_768 {
    width: 50%; }
  .col13_768 {
    width: 54.16667%; }
  .col14_768 {
    width: 58.33333%; }
  .col15_768 {
    width: 62.5%; }
  .col16_768 {
    width: 66.66667%; }
  .col17_768 {
    width: 70.83333%; }
  .col18_768 {
    width: 75%; }
  .col19_768 {
    width: 79.16667%; }
  .col20_768 {
    width: 83.33333%; }
  .col21_768 {
    width: 87.5%; }
  .col22_768 {
    width: 91.66667%; }
  .col23_768 {
    width: 95.83333%; }
  .col24_768 {
    width: 100%; }
  .col1_768, .col2_768, .col3_768, .col4_768, .col5_768, .col6_768,
  .col7_768, .col8_768, .col9_768, .col10_768, .col11_768, .col12_768,
  .col13_768, .col14_768, .col15_768, .col16_768, .col17_768, .col18_768,
  .col19_768, .col20_768, .col21_768, .col22_768, .col23_768, .col24_768 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 700px) {
  .col1_700 {
    width: 4.16667%; }
  .col2_700 {
    width: 8.33333%; }
  .col3_700 {
    width: 12.5%; }
  .col4_700 {
    width: 16.66667%; }
  .col5_700 {
    width: 20.83333%; }
  .col6_700 {
    width: 25%; }
  .col7_700 {
    width: 29.16667%; }
  .col8_700 {
    width: 33.33333%; }
  .col9_700 {
    width: 37.5%; }
  .col10_700 {
    width: 41.66667%; }
  .col11_700 {
    width: 45.83333%; }
  .col12_700 {
    width: 50%; }
  .col13_700 {
    width: 54.16667%; }
  .col14_700 {
    width: 58.33333%; }
  .col15_700 {
    width: 62.5%; }
  .col16_700 {
    width: 66.66667%; }
  .col17_700 {
    width: 70.83333%; }
  .col18_700 {
    width: 75%; }
  .col19_700 {
    width: 79.16667%; }
  .col20_700 {
    width: 83.33333%; }
  .col21_700 {
    width: 87.5%; }
  .col22_700 {
    width: 91.66667%; }
  .col23_700 {
    width: 95.83333%; }
  .col24_700 {
    width: 100%; }
  .col1_700, .col2_700, .col3_700, .col4_700, .col5_700, .col6_700,
  .col7_700, .col8_700, .col9_700, .col10_700, .col11_700, .col12_700,
  .col13_700, .col14_700, .col15_700, .col16_700, .col17_700, .col18_700,
  .col19_700, .col20_700, .col21_700, .col22_700, .col23_700, .col24_700 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 600px) {
  .col1_600 {
    width: 4.16667%; }
  .col2_600 {
    width: 8.33333%; }
  .col3_600 {
    width: 12.5%; }
  .col4_600 {
    width: 16.66667%; }
  .col5_600 {
    width: 20.83333%; }
  .col6_600 {
    width: 25%; }
  .col7_600 {
    width: 29.16667%; }
  .col8_600 {
    width: 33.33333%; }
  .col9_600 {
    width: 37.5%; }
  .col10_600 {
    width: 41.66667%; }
  .col11_600 {
    width: 45.83333%; }
  .col12_600 {
    width: 50%; }
  .col13_600 {
    width: 54.16667%; }
  .col14_600 {
    width: 58.33333%; }
  .col15_600 {
    width: 62.5%; }
  .col16_600 {
    width: 66.66667%; }
  .col17_600 {
    width: 70.83333%; }
  .col18_600 {
    width: 75%; }
  .col19_600 {
    width: 79.16667%; }
  .col20_600 {
    width: 83.33333%; }
  .col21_600 {
    width: 87.5%; }
  .col22_600 {
    width: 91.66667%; }
  .col23_600 {
    width: 95.83333%; }
  .col24_600 {
    width: 100%; }
  .col1_600, .col2_600, .col3_600, .col4_600, .col5_600, .col6_600,
  .col7_600, .col8_600, .col9_600, .col10_600, .col11_600, .col12_600,
  .col13_600, .col14_600, .col15_600, .col16_600, .col17_600, .col18_600,
  .col19_600, .col20_600, .col21_600, .col22_600, .col23_600, .col24_600 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 500px) {
  .col1_500 {
    width: 4.16667%; }
  .col2_500 {
    width: 8.33333%; }
  .col3_500 {
    width: 12.5%; }
  .col4_500 {
    width: 16.66667%; }
  .col5_500 {
    width: 20.83333%; }
  .col6_500 {
    width: 25%; }
  .col7_500 {
    width: 29.16667%; }
  .col8_500 {
    width: 33.33333%; }
  .col9_500 {
    width: 37.5%; }
  .col10_500 {
    width: 41.66667%; }
  .col11_500 {
    width: 45.83333%; }
  .col12_500 {
    width: 50%; }
  .col13_500 {
    width: 54.16667%; }
  .col14_500 {
    width: 58.33333%; }
  .col15_500 {
    width: 62.5%; }
  .col16_500 {
    width: 66.66667%; }
  .col17_500 {
    width: 70.83333%; }
  .col18_500 {
    width: 75%; }
  .col19_500 {
    width: 79.16667%; }
  .col20_500 {
    width: 83.33333%; }
  .col21_500 {
    width: 87.5%; }
  .col22_500 {
    width: 91.66667%; }
  .col23_500 {
    width: 95.83333%; }
  .col24_500 {
    width: 100%; }
  .col1_500, .col2_500, .col3_500, .col4_500, .col5_500, .col6_500,
  .col7_500, .col8_500, .col9_500, .col10_500, .col11_500, .col12_500,
  .col13_500, .col14_500, .col15_500, .col16_500, .col17_500, .col18_500,
  .col19_500, .col20_500, .col21_500, .col22_500, .col23_500, .col24_500 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 400px) {
  .col1_400 {
    width: 4.16667%; }
  .col2_400 {
    width: 8.33333%; }
  .col3_400 {
    width: 12.5%; }
  .col4_400 {
    width: 16.66667%; }
  .col5_400 {
    width: 20.83333%; }
  .col6_400 {
    width: 25%; }
  .col7_400 {
    width: 29.16667%; }
  .col8_400 {
    width: 33.33333%; }
  .col9_400 {
    width: 37.5%; }
  .col10_400 {
    width: 41.66667%; }
  .col11_400 {
    width: 45.83333%; }
  .col12_400 {
    width: 50%; }
  .col13_400 {
    width: 54.16667%; }
  .col14_400 {
    width: 58.33333%; }
  .col15_400 {
    width: 62.5%; }
  .col16_400 {
    width: 66.66667%; }
  .col17_400 {
    width: 70.83333%; }
  .col18_400 {
    width: 75%; }
  .col19_400 {
    width: 79.16667%; }
  .col20_400 {
    width: 83.33333%; }
  .col21_400 {
    width: 87.5%; }
  .col22_400 {
    width: 91.66667%; }
  .col23_400 {
    width: 95.83333%; }
  .col24_400 {
    width: 100%; }
  .col1_400, .col2_400, .col3_400, .col4_400, .col5_400, .col6_400,
  .col7_400, .col8_400, .col9_400, .col10_400, .col11_400, .col12_400,
  .col13_400, .col14_400, .col15_400, .col16_400, .col17_400, .col18_400,
  .col19_400, .col20_400, .col21_400, .col22_400, .col23_400, .col24_400 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

@media screen and (max-width: 300px) {
  .col1_300 {
    width: 4.16667%; }
  .col2_300 {
    width: 8.33333%; }
  .col3_300 {
    width: 12.5%; }
  .col4_300 {
    width: 16.66667%; }
  .col5_300 {
    width: 20.83333%; }
  .col6_300 {
    width: 25%; }
  .col7_300 {
    width: 29.16667%; }
  .col8_300 {
    width: 33.33333%; }
  .col9_300 {
    width: 37.5%; }
  .col10_300 {
    width: 41.66667%; }
  .col11_300 {
    width: 45.83333%; }
  .col12_300 {
    width: 50%; }
  .col13_300 {
    width: 54.16667%; }
  .col14_300 {
    width: 58.33333%; }
  .col15_300 {
    width: 62.5%; }
  .col16_300 {
    width: 66.66667%; }
  .col17_300 {
    width: 70.83333%; }
  .col18_300 {
    width: 75%; }
  .col19_300 {
    width: 79.16667%; }
  .col20_300 {
    width: 83.33333%; }
  .col21_300 {
    width: 87.5%; }
  .col22_300 {
    width: 91.66667%; }
  .col23_300 {
    width: 95.83333%; }
  .col24_300 {
    width: 100%; }
  .col1_300, .col2_300, .col3_300, .col4_300, .col5_300, .col6_300,
  .col7_300, .col8_300, .col9_300, .col10_300, .col11_300, .col12_300,
  .col13_300, .col14_300, .col15_300, .col16_300, .col17_300, .col18_300,
  .col19_300, .col20_300, .col21_300, .col22_300, .col23_300, .col24_300 {
    display: block;
    float: left;
    min-height: 1px;
    padding: 0 1%; } }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.table {
  display: table; }

.tableCell {
  display: table-cell; }

.flex {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.fixed {
  position: fixed; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 50px; }

.floatLeft {
  float: left; }

.floatRight {
  float: right; }

.textLeft {
  text-align: left; }

.textCenter {
  text-align: center; }

.textRight {
  text-align: right; }

.alignTop {
  vertical-align: top; }

.alignMiddle {
  vertical-align: middle; }

.alignBottom {
  vertical-align: bottom; }

.caps {
  text-transform: capitalize; }

.allCaps {
  text-transform: uppercase; }

.wrapper1600 {
  max-width: 1600px;
  margin: 0 auto; }
  @media screen and (max-width: 1600px) {
    .wrapper1600 {
      margin: 0; } }

.wrapper1500 {
  max-width: 1500px;
  margin: 0 auto; }
  @media screen and (max-width: 1500px) {
    .wrapper1500 {
      margin: 0; } }

.wrapper1400 {
  max-width: 1400px;
  margin: 0 auto; }
  @media screen and (max-width: 1400px) {
    .wrapper1400 {
      margin: 0; } }

.wrapper1300 {
  max-width: 1300px;
  margin: 0 auto; }
  @media screen and (max-width: 1300px) {
    .wrapper1300 {
      margin: 0; } }

.wrapper1200 {
  max-width: 1200px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .wrapper1200 {
      margin: 0; } }

.wrapper1100 {
  max-width: 1100px;
  margin: 0 auto; }
  @media screen and (max-width: 1100px) {
    .wrapper1100 {
      margin: 0; } }

.wrapper1024 {
  max-width: 1024px;
  margin: 0 auto; }
  @media screen and (max-width: 1024px) {
    .wrapper1024 {
      margin: 0; } }

.yPadding250 {
  padding-top: 250px;
  padding-bottom: 250px; }
  @media screen and (max-width: 1600px) {
    .yPadding250 {
      padding-top: 200px;
      padding-bottom: 200px; } }
  @media screen and (max-width: 1400px) {
    .yPadding250 {
      padding-top: 150px;
      padding-bottom: 150px; } }
  @media screen and (max-width: 1200px) {
    .yPadding250 {
      padding-top: 125px;
      padding-bottom: 125px; } }
  @media screen and (max-width: 1024px) {
    .yPadding250 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yPadding250 {
      padding-top: 70px;
      padding-bottom: 70px; } }

.yTopPadding250 {
  padding-top: 250px; }
  @media screen and (max-width: 1600px) {
    .yTopPadding250 {
      padding-top: 200px; } }
  @media screen and (max-width: 1400px) {
    .yTopPadding250 {
      padding-top: 150px; } }
  @media screen and (max-width: 1200px) {
    .yTopPadding250 {
      padding-top: 125px; } }
  @media screen and (max-width: 1024px) {
    .yTopPadding250 {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    .yTopPadding250 {
      padding-top: 70px; } }

.yBottomPadding250 {
  padding-bottom: 250px; }
  @media screen and (max-width: 1600px) {
    .yBottomPadding250 {
      padding-bottom: 200px; } }
  @media screen and (max-width: 1400px) {
    .yBottomPadding250 {
      padding-bottom: 150px; } }
  @media screen and (max-width: 1200px) {
    .yBottomPadding250 {
      padding-bottom: 125px; } }
  @media screen and (max-width: 1024px) {
    .yBottomPadding250 {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yBottomPadding250 {
      padding-bottom: 70px; } }

.yPadding200 {
  padding-top: 200px;
  padding-bottom: 200px; }
  @media screen and (max-width: 1600px) {
    .yPadding200 {
      padding-top: 175px;
      padding-bottom: 175px; } }
  @media screen and (max-width: 1400px) {
    .yPadding200 {
      padding-top: 150px;
      padding-bottom: 150px; } }
  @media screen and (max-width: 1200px) {
    .yPadding200 {
      padding-top: 125px;
      padding-bottom: 125px; } }
  @media screen and (max-width: 1024px) {
    .yPadding200 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yPadding200 {
      padding-top: 70px;
      padding-bottom: 70px; } }

.yTopPadding200 {
  padding-top: 200px; }
  @media screen and (max-width: 1600px) {
    .yTopPadding200 {
      padding-top: 175px; } }
  @media screen and (max-width: 1400px) {
    .yTopPadding200 {
      padding-top: 150px; } }
  @media screen and (max-width: 1200px) {
    .yTopPadding200 {
      padding-top: 125px; } }
  @media screen and (max-width: 1024px) {
    .yTopPadding200 {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    .yTopPadding200 {
      padding-top: 70px; } }

.yBottomPadding200 {
  padding-bottom: 200px; }
  @media screen and (max-width: 1600px) {
    .yBottomPadding200 {
      padding-bottom: 175px; } }
  @media screen and (max-width: 1400px) {
    .yBottomPadding200 {
      padding-bottom: 150px; } }
  @media screen and (max-width: 1200px) {
    .yBottomPadding200 {
      padding-bottom: 125px; } }
  @media screen and (max-width: 1024px) {
    .yBottomPadding200 {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yBottomPadding200 {
      padding-bottom: 70px; } }

.yPadding150 {
  padding-top: 150px;
  padding-bottom: 150px; }
  @media screen and (max-width: 1600px) {
    .yPadding150 {
      padding-top: 125px;
      padding-bottom: 125px; } }
  @media screen and (max-width: 1400px) {
    .yPadding150 {
      padding-top: 100px;
      padding-bottom: 100px; } }
  @media screen and (max-width: 1200px) {
    .yPadding150 {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .yPadding150 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yPadding150 {
      padding-top: 70px;
      padding-bottom: 70px; } }

.yTopPadding150 {
  padding-top: 150px; }
  @media screen and (max-width: 1600px) {
    .yTopPadding150 {
      padding-top: 125px; } }
  @media screen and (max-width: 1400px) {
    .yTopPadding150 {
      padding-top: 100px; } }
  @media screen and (max-width: 1200px) {
    .yTopPadding150 {
      padding-top: 90px; } }
  @media screen and (max-width: 1024px) {
    .yTopPadding150 {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    .yTopPadding150 {
      padding-top: 70px; } }

.yBottomPadding150 {
  padding-bottom: 150px; }
  @media screen and (max-width: 1600px) {
    .yBottomPadding150 {
      padding-bottom: 125px; } }
  @media screen and (max-width: 1400px) {
    .yBottomPadding150 {
      padding-bottom: 100px; } }
  @media screen and (max-width: 1200px) {
    .yBottomPadding150 {
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .yBottomPadding150 {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yBottomPadding150 {
      padding-bottom: 70px; } }

.yPadding100 {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media screen and (max-width: 1400px) {
    .yPadding100 {
      padding-top: 90px;
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .yPadding100 {
      padding-top: 80px;
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yPadding100 {
      padding-top: 70px;
      padding-bottom: 70px; } }

.yTopPadding100 {
  padding-top: 100px; }
  @media screen and (max-width: 1400px) {
    .yTopPadding100 {
      padding-top: 90px; } }
  @media screen and (max-width: 1024px) {
    .yTopPadding100 {
      padding-top: 80px; } }
  @media screen and (max-width: 768px) {
    .yTopPadding100 {
      padding-top: 70px; } }

.yBottomPadding100 {
  padding-bottom: 100px; }
  @media screen and (max-width: 1400px) {
    .yBottomPadding100 {
      padding-bottom: 90px; } }
  @media screen and (max-width: 1024px) {
    .yBottomPadding100 {
      padding-bottom: 80px; } }
  @media screen and (max-width: 768px) {
    .yBottomPadding100 {
      padding-bottom: 70px; } }

.yPadding70 {
  padding-top: 70px;
  padding-bottom: 70px; }

.yTopPadding70 {
  padding-top: 70px; }

.yBottomPadding70 {
  padding-bottom: 70px; }

.yPadding50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.yTopPadding50 {
  padding-top: 50px; }

.yBottomPadding50 {
  padding-bottom: 50px; }

.xPadding15 {
  padding-right: 15%;
  padding-left: 15%; }
  @media screen and (max-width: 1400px) {
    .xPadding15 {
      padding-right: 12%;
      padding-left: 12%; } }
  @media screen and (max-width: 1200px) {
    .xPadding15 {
      padding-right: 10%;
      padding-left: 10%; } }
  @media screen and (max-width: 1024px) {
    .xPadding15 {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding15 {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding15 {
  padding-right: 15%; }
  @media screen and (max-width: 1400px) {
    .xRightPadding15 {
      padding-right: 12%; } }
  @media screen and (max-width: 1200px) {
    .xRightPadding15 {
      padding-right: 10%; } }
  @media screen and (max-width: 1024px) {
    .xRightPadding15 {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding15 {
      padding-right: 20px; } }

.xLeftPadding15 {
  padding-left: 15%; }
  @media screen and (max-width: 1400px) {
    .xLeftPadding15 {
      padding-left: 12%; } }
  @media screen and (max-width: 1200px) {
    .xLeftPadding15 {
      padding-left: 10%; } }
  @media screen and (max-width: 1024px) {
    .xLeftPadding15 {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding15 {
      padding-left: 20px; } }

.xPadding12 {
  padding-right: 12%;
  padding-left: 12%; }
  @media screen and (max-width: 1200px) {
    .xPadding12 {
      padding-right: 10%;
      padding-left: 10%; } }
  @media screen and (max-width: 1024px) {
    .xPadding12 {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding12 {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding12 {
  padding-right: 12%; }
  @media screen and (max-width: 1200px) {
    .xRightPadding12 {
      padding-right: 10%; } }
  @media screen and (max-width: 1024px) {
    .xRightPadding12 {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding12 {
      padding-right: 20px; } }

.xLeftPadding12 {
  padding-left: 12%; }
  @media screen and (max-width: 1200px) {
    .xLeftPadding12 {
      padding-left: 10%; } }
  @media screen and (max-width: 1024px) {
    .xLeftPadding12 {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding12 {
      padding-left: 20px; } }

.xPadding8 {
  padding-right: 8%;
  padding-left: 8%; }
  @media screen and (max-width: 1200px) {
    .xPadding8 {
      padding-right: 6%;
      padding-left: 6%; } }
  @media screen and (max-width: 1024px) {
    .xPadding8 {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding8 {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding8 {
  padding-right: 8%; }
  @media screen and (max-width: 1200px) {
    .xRightPadding8 {
      padding-right: 6%; } }
  @media screen and (max-width: 1024px) {
    .xRightPadding8 {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding8 {
      padding-right: 20px; } }

.xLeftPadding8 {
  padding-left: 8%; }
  @media screen and (max-width: 1200px) {
    .xLeftPadding8 {
      padding-left: 6%; } }
  @media screen and (max-width: 1024px) {
    .xLeftPadding8 {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding8 {
      padding-left: 20px; } }

.xPadding6 {
  padding-right: 6%;
  padding-left: 6%; }
  @media screen and (max-width: 1200px) {
    .xPadding6 {
      padding-right: 5%;
      padding-left: 5%; } }
  @media screen and (max-width: 1024px) {
    .xPadding6 {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding6 {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding6 {
  padding-right: 6%; }
  @media screen and (max-width: 1200px) {
    .xRightPadding6 {
      padding-right: 5%; } }
  @media screen and (max-width: 1024px) {
    .xRightPadding6 {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding6 {
      padding-right: 20px; } }

.xLeftPadding6 {
  padding-left: 6%; }
  @media screen and (max-width: 1200px) {
    .xLeftPadding6 {
      padding-left: 5%; } }
  @media screen and (max-width: 1024px) {
    .xLeftPadding6 {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding6 {
      padding-left: 20px; } }

.xPadding5 {
  padding-right: 5%;
  padding-left: 5%; }
  @media screen and (max-width: 1024px) {
    .xPadding5 {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding5 {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding5 {
  padding-right: 5%; }
  @media screen and (max-width: 1024px) {
    .xRightPadding5 {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding5 {
      padding-right: 20px; } }

.xLeftPadding5 {
  padding-left: 5%; }
  @media screen and (max-width: 1024px) {
    .xLeftPadding5 {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding5 {
      padding-left: 20px; } }

.xPadding50 {
  padding-right: 50px;
  padding-left: 50px; }
  @media screen and (max-width: 1024px) {
    .xPadding50 {
      padding-right: 30px;
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xPadding50 {
      padding-right: 20px;
      padding-left: 20px; } }

.xRightPadding50 {
  padding-right: 50px; }
  @media screen and (max-width: 1024px) {
    .xRightPadding50 {
      padding-right: 30px; } }
  @media screen and (max-width: 768px) {
    .xRightPadding50 {
      padding-right: 20px; } }

.xLeftPadding50 {
  padding-left: 50px; }
  @media screen and (max-width: 1024px) {
    .xLeftPadding50 {
      padding-left: 30px; } }
  @media screen and (max-width: 768px) {
    .xLeftPadding50 {
      padding-left: 20px; } }

.noTopPadding {
  padding-top: 0; }

.noRightPadding {
  padding-right: 0; }

.noBottomPadding {
  padding-bottom: 0; }

.noLeftPadding {
  padding-left: 0; }

.noXPadding {
  padding-right: 0;
  padding-left: 0; }

.noYPadding {
  padding-top: 0;
  padding-bottom: 0; }

.noPadding {
  padding: 0; }

.noTopMargin {
  margin-top: 0; }

.noRightMargin {
  margin-right: 0; }

.noBottomMargin {
  margin-bottom: 0; }

.noLeftMargin {
  margin-left: 0; }

.noXMargin {
  margin-right: 0;
  margin-left: 0; }

.noYMargin {
  margin-top: 0;
  margin-bottom: 0; }

.top0 {
  top: 0; }

.right0 {
  right: 0; }

.bottom0 {
  bottom: 0; }

.left0 {
  left: 0; }

.trbl0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.nowrap {
  white-space: nowrap; }

.clearfix {
  display: block;
  clear: both; }

.noscroll {
  position: fixed;
  width: 100%;
  overflow-y: scroll; }
  @media screen and (max-width: 768px) {
    .noscroll {
      overflow-y: hidden; } }

.noselect {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.hide {
  display: none; }

@media screen and (max-width: 1800px) {
  .hide_1800 {
    display: none; } }

@media screen and (max-width: 1700px) {
  .hide_1700 {
    display: none; } }

@media screen and (max-width: 1600px) {
  .hide_1600 {
    display: none; } }

@media screen and (max-width: 1500px) {
  .hide_1500 {
    display: none; } }

@media screen and (max-width: 1400px) {
  .hide_1400 {
    display: none; } }

@media screen and (max-width: 1300px) {
  .hide_1300 {
    display: none; } }

@media screen and (max-width: 1200px) {
  .hide_1200 {
    display: none; } }

@media screen and (max-width: 1100px) {
  .hide_1100 {
    display: none; } }

@media screen and (max-width: 1024px) {
  .hide_1024 {
    display: none; } }

@media screen and (max-width: 1000px) {
  .hide_1000 {
    display: none; } }

@media screen and (max-width: 900px) {
  .hide_900 {
    display: none; } }

@media screen and (max-width: 800px) {
  .hide_800 {
    display: none; } }

@media screen and (max-width: 768px) {
  .hide_768 {
    display: none; } }

@media screen and (max-width: 700px) {
  .hide_700 {
    display: none; } }

@media screen and (max-width: 600px) {
  .hide_600 {
    display: none; } }

@media screen and (max-width: 500px) {
  .hide_500 {
    display: none; } }

@media screen and (max-width: 400px) {
  .hide_400 {
    display: none; } }

@media screen and (max-width: 300px) {
  .hide_300 {
    display: none; } }

.show {
  display: block; }

.show_1800 {
  display: none; }
  @media screen and (max-width: 1800px) {
    .show_1800 {
      display: block; } }

.show_1700 {
  display: none; }
  @media screen and (max-width: 1700px) {
    .show_1700 {
      display: block; } }

.show_1600 {
  display: none; }
  @media screen and (max-width: 1600px) {
    .show_1600 {
      display: block; } }

.show_1500 {
  display: none; }
  @media screen and (max-width: 1500px) {
    .show_1500 {
      display: block; } }

.show_1400 {
  display: none; }
  @media screen and (max-width: 1400px) {
    .show_1400 {
      display: block; } }

.show_1300 {
  display: none; }
  @media screen and (max-width: 1300px) {
    .show_1300 {
      display: block; } }

.show_1200 {
  display: none; }
  @media screen and (max-width: 1200px) {
    .show_1200 {
      display: block; } }

.show_1100 {
  display: none; }
  @media screen and (max-width: 1100px) {
    .show_1100 {
      display: block; } }

.show_1024 {
  display: none; }
  @media screen and (max-width: 1024px) {
    .show_1024 {
      display: block; } }

.show_1000 {
  display: none; }
  @media screen and (max-width: 1000px) {
    .show_1000 {
      display: block; } }

.show_900 {
  display: none; }
  @media screen and (max-width: 900px) {
    .show_900 {
      display: block; } }

.show_800 {
  display: none; }
  @media screen and (max-width: 800px) {
    .show_800 {
      display: block; } }

.show_768 {
  display: none; }
  @media screen and (max-width: 768px) {
    .show_768 {
      display: block; } }

.show_700 {
  display: none; }
  @media screen and (max-width: 700px) {
    .show_700 {
      display: block; } }

.show_600 {
  display: none; }
  @media screen and (max-width: 600px) {
    .show_600 {
      display: block; } }

.show_500 {
  display: none; }
  @media screen and (max-width: 500px) {
    .show_500 {
      display: block; } }

.show_400 {
  display: none; }
  @media screen and (max-width: 400px) {
    .show_400 {
      display: block; } }

.show_300 {
  display: none; }
  @media screen and (max-width: 300px) {
    .show_300 {
      display: block; } }

.zIndex-1 {
  z-index: -1; }

.zIndex0 {
  z-index: 0; }

.zIndex1 {
  z-index: 1; }

.zIndex2 {
  z-index: 2; }

.zIndex3 {
  z-index: 3; }

.zIndex4 {
  z-index: 4; }

.zIndex5 {
  z-index: 5; }

.zIndex6 {
  z-index: 6; }

.zIndex7 {
  z-index: 7; }

.zIndex8 {
  z-index: 8; }

.zIndex9 {
  z-index: 9; }

.zIndex10 {
  z-index: 10; }

h1, .h1 {
  font: 110px/1.1 "NoeDisplay-Regular", sans-serif;
  color: #fff; }
  @media screen and (max-width: 1024px) {
    h1, .h1 {
      font-size: 90px; } }
  @media screen and (max-width: 768px) {
    h1, .h1 {
      font-size: 80px; } }
  @media screen and (max-width: 600px) {
    h1, .h1 {
      font-size: 75px; } }
  @media screen and (max-width: 500px) {
    h1, .h1 {
      font-size: 65px; } }
  @media screen and (max-width: 400px) {
    h1, .h1 {
      font-size: 60px; } }
  @media screen and (max-width: 350px) {
    h1, .h1 {
      font-size: 55px; } }

h2, .h2 {
  font: 50px/1.1 "NoeDisplay-Regular", sans-serif;
  color: #1f273b;
  text-transform: uppercase; }
  @media screen and (max-width: 1200px) {
    h2, .h2 {
      font-size: 45px; } }
  @media screen and (max-width: 1024px) {
    h2, .h2 {
      font-size: 35px; } }
  @media screen and (max-width: 500px) {
    h2, .h2 {
      font-size: 30px; } }

h3, .h3 {
  font: 24px/1 "Akkurat-Regular", sans-serif;
  color: #fff;
  text-transform: uppercase;
  margin: 0 auto 35px;
  max-width: 375px;
  padding-bottom: 15px;
  border-bottom: 1px solid #fff; }
  @media screen and (max-width: 1200px) {
    h3, .h3 {
      font-size: 22px; } }
  @media screen and (max-width: 1024px) {
    h3, .h3 {
      font-size: 20px; } }
  @media screen and (max-width: 768px) {
    h3, .h3 {
      font-size: 18px; } }
  @media screen and (max-width: 600px) {
    h3, .h3 {
      font-size: 16px; } }
  @media screen and (max-width: 500px) {
    h3, .h3 {
      font-size: 14px; } }

p {
  font: 17px/1.75 "Akkurat-Regular", sans-serif;
  color: #9ca0af; }
  @media screen and (max-width: 600px) {
    p {
      font-size: 16px; } }

a {
  color: #fff;
  font: 17px/1 "Akkurat-Regular", sans-serif;
  display: inline-block;
  text-decoration: none;
  cursor: pointer; }

li {
  font: 17px/1.25 "Akkurat-Regular", sans-serif;
  color: #1f273b;
  margin-bottom: 10px;
  text-transform: uppercase; }
  li:before {
    content: "\2022";
    color: #bd663a;
    font-weight: bold;
    display: inline-block;
    width: 2em;
    margin-left: -2em; }
  @media screen and (max-width: 600px) {
    li {
      font-size: 16px; } }

.btn {
  position: relative;
  font: 17px/1 "Akkurat-Regular", sans-serif;
  color: #1f273b; }
  .btn span {
    position: absolute;
    width: 36px;
    height: 36px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden; }

header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000; }
  header ul:first-child {
    float: left;
    margin-top: 55px; }
    @media screen and (max-width: 1300px) {
      header ul:first-child {
        margin-top: 33px; } }
    header ul:first-child li {
      display: inline-block; }
      header ul:first-child li img {
        position: absolute;
        top: 0;
        left: 0;
        max-width: 225px; }
        @media screen and (max-width: 1200px) {
          header ul:first-child li img {
            width: 190px; } }
        @media screen and (max-width: 1024px) {
          header ul:first-child li img {
            width: 170px; } }
        @media screen and (max-width: 500px) {
          header ul:first-child li img {
            width: 150px; } }
        @media screen and (max-width: 350px) {
          header ul:first-child li img {
            width: 120px; } }
  header ul.desktop {
    float: right; }
    @media screen and (max-width: 1000px) {
      header ul.desktop .faceInsta {
        display: none; } }
    @media screen and (max-width: 768px) {
      header ul.desktop .faceInsta {
        display: inline-block; } }
    @media screen and (max-width: 600px) {
      header ul.desktop .faceInsta {
        display: none; } }
    header ul.desktop li {
      display: inline-block;
      vertical-align: top;
      height: 145px;
      margin-right: 40px;
      line-height: 145px; }
      @media screen and (max-width: 768px) {
        header ul.desktop li {
          height: 100px;
          line-height: 100px; }
          header ul.desktop li.hide_768 {
            display: none; }
          header ul.desktop li.scroll {
            display: none; } }
      header ul.desktop li:before {
        display: none; }
      header ul.desktop li .phone {
        margin-right: 100px;
        opacity: 0.5;
        transition: opacity 300ms; }
        @media screen and (max-width: 900px) {
          header ul.desktop li .phone {
            margin-right: 40px; } }
        @media screen and (max-width: 768px) {
          header ul.desktop li .phone {
            margin-right: 0; } }
        header ul.desktop li .phone:hover {
          opacity: 1; }
        header ul.desktop li .phone:after {
          display: none; }
        header ul.desktop li .phone img {
          width: 13px;
          height: auto;
          fill: #fff;
          margin-left: 10px; }
      header ul.desktop li a {
        padding-bottom: 5px;
        position: relative;
        font: 17px/1 "Akkurat-Regular", sans-serif;
        color: #fff;
        transition: color 300ms;
        letter-spacing: 0.02em;
        text-transform: none; }
        header ul.desktop li a:after {
          content: '';
          position: absolute;
          height: 1px;
          top: 100%;
          right: 100%;
          left: 0;
          background: #ffffff;
          transition: right 300ms cubic-bezier(0.215, 0.61, 0.355, 1); }
        header ul.desktop li a:hover:after {
          right: 0%; }

footer {
  position: relative;
  background: #1f273b; }
  footer li {
    color: white;
    text-transform: none;
    letter-spacing: 0.02em; }
    footer li:before {
      display: none; }
    footer li:nth-of-type(2n) {
      margin-bottom: 50px; }
    footer li.tel {
      letter-spacing: 0.075em; }
  footer .col6 {
    height: 100%; }
  footer .bigLogo {
    padding-left: 18%; }
    @media screen and (max-width: 1024px) {
      footer .bigLogo {
        height: 240px;
        padding-top: 10%; } }
    @media screen and (max-width: 768px) {
      footer .bigLogo {
        padding-left: 0;
        padding-top: 20px;
        padding-bottom: 20px;
        height: 215px; } }
  footer .logoCercle {
    height: 110px;
    margin-bottom: 110px; }
    @media screen and (max-width: 1349px) {
      footer .logoCercle {
        margin-bottom: 90px; } }
    @media screen and (max-width: 1085px) {
      footer .logoCercle {
        margin-bottom: 110px; } }
    @media screen and (max-width: 768px) {
      footer .logoCercle {
        margin-bottom: 130px; } }
    @media screen and (max-width: 624px) {
      footer .logoCercle {
        margin-bottom: 170px; } }
    @media screen and (max-width: 600px) {
      footer .logoCercle {
        margin-bottom: 30px; } }
  footer .facebook {
    bottom: 0; }
  @media screen and (max-width: 600px) {
    footer .facebookLink {
      margin-bottom: 0 !important; } }
  footer .address {
    color: #9ca0af; }
  footer a {
    position: relative;
    transition: color 300ms; }
    footer a:after {
      content: '';
      position: absolute;
      height: 1px;
      top: 100%;
      right: 0%;
      left: 0;
      background: #ffffff;
      transition: right 300ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    footer a:hover:after {
      right: 100%; }
  footer .tel, footer .tel a {
    font: 30px "Akkurat-Light", sans-serif; }
    footer .tel:after, footer .tel a:after {
      display: none; }
  @media screen and (max-width: 1024px) {
    footer ul.grosLogo {
      width: 42%; }
    footer ul.social {
      width: 24%;
      padding-left: 6%; } }
  @media screen and (max-width: 768px) {
    footer ul.grosLogo {
      width: 100%;
      text-align: center;
      margin-bottom: 5%; }
    footer ul.coord, footer ul.social {
      width: 50%;
      text-align: center;
      padding-left: 0; }
    footer ul.coord {
      padding-left: 12%; } }
  @media screen and (max-width: 600px) {
    footer ul.coord, footer ul.social {
      width: 100%;
      padding: 0; } }
  @media screen and (max-width: 400px) {
    footer ul.grosLogo {
      margin-bottom: 8%; } }

.copyright {
  position: relative;
  background: #14192c;
  padding-right: 100px;
  padding-left: 100px;
  color: #9ca0af; }
  @media screen and (max-width: 600px) {
    .copyright {
      padding-right: 0;
      padding-left: 0; } }
  .copyright p {
    margin-bottom: 5px;
    float: left;
    line-height: 1; }
    @media screen and (max-width: 600px) {
      .copyright p {
        margin-bottom: 10px !important; } }
  .copyright a {
    float: right;
    color: #9ca0af; }
  @media screen and (max-width: 600px) {
    .copyright p, .copyright a {
      float: none;
      margin: 0 auto;
      display: table; } }

#infolettreOverlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 998;
  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms, visibility 0ms ease 500ms; }

#infolettreBox {
  position: fixed;
  top: 0;
  right: 0;
  background: #1f273b;
  width: calc(100vh * 0.65);
  height: 100%;
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms, margin 500ms, visibility 0ms ease 500ms; }
  @media screen and (min-width: 769px) {
    #infolettreBox {
      min-width: 500px; } }
  @media screen and (max-width: 768px) {
    #infolettreBox {
      top: 10%;
      left: 0;
      right: 0;
      margin-right: auto;
      margin-left: auto;
      width: 650px;
      max-width: 95%;
      height: 90%; } }
  @media screen and (max-width: 600px) {
    #infolettreBox {
      height: 80%;
      min-height: 650px;
      max-height: 98%;
      top: 1%; } }
  #infolettreBox .content {
    position: absolute;
    top: 115px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    height: 75%;
    background: #fff;
    padding: 90px 80px 80px 80px;
    text-align: center; }
    @media screen and (max-width: 768px) {
      #infolettreBox .content {
        height: 85%;
        top: 60px;
        width: 85%; } }
    @media screen and (max-height: 900px) {
      #infolettreBox .content {
        padding: 50px 70px 50px 70px; } }
    @media screen and (max-height: 800px) {
      #infolettreBox .content {
        top: 90px;
        padding: 40px; } }
    @media screen and (max-height: 700px) {
      #infolettreBox .content {
        height: 85%; } }
    @media screen and (max-height: 600px) {
      #infolettreBox .content {
        top: 70px; } }
    @media screen and (max-width: 600px) {
      #infolettreBox .content {
        top: 60px;
        padding: 30px; } }
    @media screen and (max-width: 350px) {
      #infolettreBox .content {
        padding: 25px; } }
    #infolettreBox .content h2 {
      text-transform: none;
      font-size: 65px;
      margin-bottom: 35px; }
      @media screen and (max-height: 900px) {
        #infolettreBox .content h2 {
          font-size: 55px;
          margin-bottom: 20px; } }
      @media screen and (max-width: 600px) {
        #infolettreBox .content h2 {
          font-size: 50px;
          margin-bottom: 25px; } }
      @media screen and (max-height: 600px) {
        #infolettreBox .content h2 {
          margin-bottom: 10px; } }
    @media screen and (max-height: 900px) {
      #infolettreBox .content p {
        font-size: 16px; } }
    @media screen and (max-height: 600px) {
      #infolettreBox .content p {
        font-size: 15px; } }
    @media screen and (max-width: 600px) {
      #infolettreBox .content p {
        font-size: 15px; } }
    #infolettreBox .content #popupInfolettre {
      margin-top: 35px;
      text-align: left; }
      #infolettreBox .content #popupInfolettre .input-field {
        width: 100%;
        font-family: "Akkurat-Regular", sans-serif;
        padding: 0;
        margin-bottom: 45px; }
      @media screen and (max-height: 800px) {
        #infolettreBox .content #popupInfolettre {
          margin-top: 20px; }
          #infolettreBox .content #popupInfolettre .input-field {
            margin-bottom: 30px; } }
      @media screen and (max-height: 700px) {
        #infolettreBox .content #popupInfolettre {
          margin-top: 10px; } }
      @media screen and (max-width: 600px) {
        #infolettreBox .content #popupInfolettre {
          margin-top: 20px;
          font-size: 15px; }
          #infolettreBox .content #popupInfolettre .hexaBtn {
            margin-top: 15px;
            font-size: 15px; }
          #infolettreBox .content #popupInfolettre .input-field {
            margin-bottom: 25px; } }
  #infolettreBox #polygon {
    position: absolute;
    width: 230px;
    transform: scaleX(-1) rotate(-135deg);
    bottom: -35px;
    left: 75px;
    z-index: -1; }
    @media screen and (max-width: 768px) {
      #infolettreBox #polygon {
        width: 150px;
        left: 50px; } }
    @media screen and (max-width: 600px) {
      #infolettreBox #polygon {
        left: 35px;
        bottom: -50px; } }
  #infolettreBox #closePopup {
    position: absolute;
    display: inline-block;
    top: -50px;
    right: 0;
    font-family: "Akkurat-Light", sans-serif;
    text-transform: uppercase; }
    #infolettreBox #closePopup .x {
      display: inline-block;
      position: relative;
      margin-left: 10px;
      width: 15px;
      height: 10px; }
      #infolettreBox #closePopup .x:before, #infolettreBox #closePopup .x:after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background: #bd663a;
        width: 1px;
        height: 17px; }
      #infolettreBox #closePopup .x:before {
        transform: translate(-50%, -50%) rotate(45deg); }
      #infolettreBox #closePopup .x:after {
        transform: translate(-50%, -50%) rotate(-45deg); }
    #infolettreBox #closePopup:hover .x:before, #infolettreBox #closePopup:hover .x:after {
      background: #fff; }
    @media screen and (max-width: 768px) {
      #infolettreBox #closePopup {
        top: -35px; } }

.show-infolettrePopup #infolettreOverlay {
  /* Décommenter le bloc quand on remet l'infolettre */
  opacity: 1;
  visibility: visible;
  transition: opacity 500ms; }

.show-infolettrePopup #infolettreBox {
  opacity: 1;
  visibility: visible;
  transition: opacity 500ms, margin 500ms; }

#popupInfolettre fieldset div.error {
  position: absolute;
  top: 90% !important;
  padding-top: 7px;
  left: 0 !important;
  font-size: 13px; }

.popupBox {
  --popup-width: 300px;
  --popup-x-size: 15px;
  position: fixed;
  bottom: 0;
  right: 0;
  background: #1f273b;
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transition: opacity 500ms, margin 500ms, visibility 0ms ease 500ms;
  color: white;
  font: 17px/1.25 "Akkurat-Regular", sans-serif;
  letter-spacing: 0.075em;
  width: 300px;
  text-align: center;
  padding-bottom: 50px; }
  .popupBox__point {
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0; }
  .popupBox__point-content {
    width: 0;
    height: 0;
    border-left: calc(var(--popup-width)/2) solid transparent;
    border-right: calc(var(--popup-width)/2) solid transparent;
    border-bottom: calc(var(--popup-width)/3) solid #1f273b; }
  .popupBox__rooms {
    font-size: 35px;
    font-weight: bold; }
  .popupBox__text {
    position: relative;
    top: -30px;
    font-family: "Akkurat-Light", sans-serif; }
  .popupBox__main {
    font-size: 30px; }
    .popupBox__main sup {
      vertical-align: super;
      font-size: 0.5em; }
  .popupBox__close-container {
    position: absolute;
    bottom: 5px;
    width: 100%;
    padding: 20px 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Akkurat-Light", sans-serif;
    opacity: 1;
    transition: opacity 0.3s ease;
    text-transform: uppercase;
    font-size: 14px; }
    .popupBox__close-container:hover {
      opacity: 0.6; }
  .popupBox__close-x {
    width: var(--popup-x-size);
    height: var(--popup-x-size);
    position: relative;
    margin-left: 5px; }
    .popupBox__close-x::before, .popupBox__close-x::after {
      content: '';
      position: absolute;
      height: 1px;
      width: var(--popup-x-size);
      background-color: #bd663a;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
    .popupBox__close-x::after {
      transform: translate(-50%, -50%) rotate(-45deg); }

.show-infolettrePopup .popupBox {
  opacity: 1;
  visibility: visible;
  transition: opacity 500ms, margin 500ms; }

.content .hero {
  position: relative;
  height: 100vh;
  background: url("../images/xagone_banniere.jpg") no-repeat center;
  background-size: cover;
  overflow: hidden; }
  .content .hero .logoCercle {
    height: 115px;
    top: 30%;
    position: absolute;
    right: 15vh; }
    @media screen and (max-width: 1024px) {
      .content .hero .logoCercle {
        height: 100px; } }
    @media screen and (max-width: 768px) {
      .content .hero .logoCercle {
        display: none; } }
  .content .hero .scrollToDescription {
    position: absolute;
    bottom: 100px;
    right: 50px;
    transform: rotate(90deg);
    color: #fff;
    opacity: 0.4;
    transition: opacity 300ms;
    text-decoration: none; }
    @media screen and (max-width: 500px) {
      .content .hero .scrollToDescription {
        display: none; } }
    .content .hero .scrollToDescription:hover {
      opacity: 1; }
      .content .hero .scrollToDescription:hover .fleche {
        transform: translateX(5px);
        transition: transform 500ms; }
    .content .hero .scrollToDescription .fleche {
      width: 22px;
      height: 18px;
      right: -35px;
      position: absolute;
      bottom: -2px;
      transition: transform 500ms; }
  .content .hero .col24 {
    position: absolute;
    top: 35%;
    left: 0; }
    @media screen and (max-width: 600px) {
      .content .hero .col24 {
        top: 30%; } }
    .content .hero .col24 .text {
      position: relative;
      z-index: 1;
      text-align: center;
      max-width: 975px;
      margin: 0 auto;
      padding: 0 50px; }
      .content .hero .col24 .text h1 {
        margin: 0 auto;
        margin-bottom: 35px;
        width: 100%; }
        @media screen and (max-width: 768px) {
          .content .hero .col24 .text h1 {
            margin: 0 auto 20px; } }
      .content .hero .col24 .text h3 {
        letter-spacing: 0.075em; }
  .content .hero a {
    letter-spacing: 0.02em; }
  .content .hero .imageWrapper {
    position: absolute;
    right: 0;
    bottom: 0; }
    @media screen and (max-width: 1024px) {
      .content .hero .imageWrapper {
        right: -100px; } }
    @media screen and (max-width: 768px) {
      .content .hero .imageWrapper {
        position: relative;
        right: 0; } }
  .content .hero .image {
    width: 500px;
    height: 750px;
    background-position: bottom center;
    background-size: contain;
    background-repeat: no-repeat; }
    @media screen and (max-height: 900px) {
      .content .hero .image {
        height: 650px; } }
    @media screen and (max-height: 800px) {
      .content .hero .image {
        height: 550px; } }
    @media screen and (max-height: 700px) {
      .content .hero .image {
        height: 450px; } }
    @media screen and (max-width: 1300px) {
      .content .hero .image {
        width: 400px; } }
    @media screen and (max-width: 768px) {
      .content .hero .image {
        width: 100%;
        height: 250px;
        background-position: 58% 0px;
        background-size: 200px;
        background-repeat: no-repeat; } }
    @media screen and (max-width: 500px) {
      .content .hero .image {
        background-position: 65% 0px; } }

.content .savoir {
  position: relative;
  background: #fff;
  z-index: -1; }
  .content .savoir #polygon {
    position: absolute;
    bottom: -160px;
    right: 0;
    max-width: 327px; }
    @media screen and (max-width: 1200px) {
      .content .savoir #polygon {
        max-width: 300px; } }
    @media screen and (max-width: 1024px) {
      .content .savoir #polygon {
        max-width: 275px; } }
    @media screen and (max-width: 768px) {
      .content .savoir #polygon {
        max-width: 250px; } }
    @media screen and (max-width: 600px) {
      .content .savoir #polygon {
        display: none; } }
  .content .savoir .col14 {
    max-width: 650px;
    margin-right: 250px; }
    @media screen and (max-width: 1600px) {
      .content .savoir .col14 {
        margin-right: 150px; } }
    @media screen and (max-width: 1400px) {
      .content .savoir .col14 {
        margin-right: 75px; } }
    @media screen and (max-width: 768px) {
      .content .savoir .col14 {
        margin-right: 250px; } }
    .content .savoir .col14 h2 {
      text-transform: none;
      margin-bottom: 50px; }
      @media screen and (max-width: 1200px) {
        .content .savoir .col14 h2 {
          margin-bottom: 75px; } }
      @media screen and (max-width: 1024px) {
        .content .savoir .col14 h2 {
          margin-bottom: 50px; } }
      @media screen and (max-width: 768px) {
        .content .savoir .col14 h2 {
          margin-bottom: 30px; } }
    .content .savoir .col14 p {
      line-height: 2;
      letter-spacing: 0.02em; }
  .content .savoir .col7 {
    z-index: 5; }
    @media screen and (max-width: 768px) {
      .content .savoir .col7 {
        float: right;
        margin: 50px 0 0; } }
    .content .savoir .col7 #savoirBullets {
      list-style: none; }
      .content .savoir .col7 #savoirBullets li {
        font-size: 15px;
        line-height: 1.5; }

.content .details {
  background-color: #f1f3f7; }
  .content .details #middle .image {
    float: left !important; }
  .content .details #middle .flex {
    float: right !important; }
  @media screen and (max-width: 768px) {
    .content .details #last .flex {
      margin-bottom: 0; } }
  .content .details .col12 {
    padding: 0; }
    .content .details .col12 .imageLiberte {
      background: url("../images/xagone_couple.jpg") no-repeat center; }
    .content .details .col12 .imageEspace {
      background: url("../images/xagone_immeuble.jpg") no-repeat center; }
    .content .details .col12 .imageGarages {
      background: url("../images/xagone_garages.jpg") no-repeat center; }
    .content .details .col12 .image {
      height: 600px;
      width: 600px;
      float: right;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
      @media screen and (max-width: 1400px) {
        .content .details .col12 .image {
          height: 500px;
          width: 500px; } }
      @media screen and (max-width: 1200px) {
        .content .details .col12 .image {
          height: 450px;
          width: 450px; } }
      @media screen and (max-width: 768px) {
        .content .details .col12 .image {
          height: 500px;
          width: 100%; } }
      @media screen and (max-width: 600px) {
        .content .details .col12 .image {
          height: auto;
          min-height: 375px; } }
      @media screen and (max-width: 400px) {
        .content .details .col12 .image {
          min-height: 350px; } }
    .content .details .col12 .flex {
      background-color: #fff;
      height: 600px;
      width: 600px;
      text-align: center; }
      @media screen and (max-width: 1400px) {
        .content .details .col12 .flex {
          height: 500px;
          width: 500px; } }
      @media screen and (max-width: 1200px) {
        .content .details .col12 .flex {
          height: 450px;
          width: 450px; } }
      @media screen and (max-width: 768px) {
        .content .details .col12 .flex {
          height: auto;
          min-height: 350px;
          width: 100%;
          margin-bottom: 50px; } }
      @media screen and (max-width: 600px) {
        .content .details .col12 .flex {
          min-height: 375px; } }
      @media screen and (max-width: 400px) {
        .content .details .col12 .flex {
          min-height: 350px; } }
      .content .details .col12 .flex div {
        padding: 70px 10%; }
        .content .details .col12 .flex div h3 {
          color: #1f273b;
          border-color: #bd663a;
          max-width: 180px;
          margin-bottom: 60px;
          letter-spacing: 0.075em;
          font-size: 15px;
          line-height: 1.5; }
          @media screen and (max-width: 1024px) {
            .content .details .col12 .flex div h3 {
              margin-bottom: 40px; } }
          @media screen and (max-width: 600px) {
            .content .details .col12 .flex div h3 {
              margin-bottom: 30px; } }
        .content .details .col12 .flex div h2 {
          text-transform: none; }
        .content .details .col12 .flex div p {
          line-height: 2;
          margin: 35px 0 0;
          letter-spacing: 0.02em; }
          @media screen and (max-width: 1400px) {
            .content .details .col12 .flex div p {
              margin: 40px 0 0; } }
          @media screen and (max-width: 1200px) {
            .content .details .col12 .flex div p {
              margin: 30px 0 0; } }
          @media screen and (max-width: 1024px) {
            .content .details .col12 .flex div p {
              margin: 20px 0 0;
              line-height: 1.75; } }
          @media screen and (max-width: 900px) {
            .content .details .col12 .flex div p {
              margin: 15px 0 0; } }
          .content .details .col12 .flex div p sup {
            vertical-align: super;
            font-size: 10px; }

.content .nouveaux {
  width: 1200px;
  background-color: #1f273b;
  margin-top: 8%;
  text-align: center;
  margin-right: auto;
  margin-left: auto; }
  @media screen and (max-width: 1400px) {
    .content .nouveaux {
      width: 1000px; } }
  @media screen and (max-width: 1200px) {
    .content .nouveaux {
      width: 900px; } }
  @media screen and (max-width: 950px) {
    .content .nouveaux {
      width: 100%; } }
  @media screen and (max-width: 600px) {
    .content .nouveaux {
      padding-left: 5%;
      padding-right: 5%; } }
  .content .nouveaux h2 {
    color: white;
    text-transform: none;
    line-height: 1.5;
    font-size: 40px; }
    @media screen and (max-width: 600px) {
      .content .nouveaux h2 {
        font-size: 30px;
        line-height: 1.1; } }
    .content .nouveaux h2 sup {
      vertical-align: super;
      font-size: 30px; }
      @media screen and (max-width: 600px) {
        .content .nouveaux h2 sup {
          font-size: 20px; } }
  .content .nouveaux p {
    text-transform: uppercase;
    font-size: 15px;
    letter-spacing: 0.02em; }
    @media screen and (max-width: 547px) {
      .content .nouveaux p {
        display: inline-grid; } }
    .content .nouveaux p span {
      color: white; }
  .content .nouveaux .titre {
    padding-top: 5%;
    padding-bottom: 2%; }
    @media screen and (max-width: 600px) {
      .content .nouveaux .titre {
        padding-top: 10%;
        padding-bottom: 6%; } }
  .content .nouveaux .sousTitre {
    padding-bottom: 6%; }
    @media screen and (max-width: 600px) {
      .content .nouveaux .sousTitre {
        padding-bottom: 10%; } }
  .content .nouveaux a {
    margin-bottom: 6%; }
    @media screen and (max-width: 600px) {
      .content .nouveaux a {
        margin-bottom: 12%; } }

.content .slickWrapper {
  position: relative;
  text-align: center; }
  .content .slickWrapper .arrow {
    position: absolute;
    width: 100px;
    height: 100px;
    transform: translate(-50%, -50%);
    background: #fff;
    color: #1f273b;
    line-height: 100px;
    font-size: 25px;
    z-index: 1000;
    transition: background-color 300ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    @media screen and (max-width: 600px) {
      .content .slickWrapper .arrow {
        width: 60px;
        height: 60px;
        line-height: 60px; } }
    @media screen and (min-width: 601px) {
      .content .slickWrapper .arrow.prev {
        top: calc(50% + 51px);
        right: -50px; } }
    @media screen and (max-width: 600px) {
      .content .slickWrapper .arrow.prev {
        bottom: -29px;
        right: 31px; } }
    .content .slickWrapper .arrow.prev:hover {
      background-color: #9ca0af;
      color: #fff; }
    @media screen and (min-width: 601px) {
      .content .slickWrapper .arrow.next {
        top: calc(50% - 51px);
        right: -50px; } }
    @media screen and (max-width: 600px) {
      .content .slickWrapper .arrow.next {
        bottom: -29px;
        right: -30px; } }
    .content .slickWrapper .arrow.next:hover {
      background-color: #9ca0af;
      color: #fff; }
  .content .slickWrapper .slick {
    position: relative;
    height: 80vh; }
    @media screen and (max-width: 600px) {
      .content .slickWrapper .slick {
        height: 50vh; } }
    .content .slickWrapper .slick div {
      width: 100%;
      height: 100%; }
    .content .slickWrapper .slick .carousel1 {
      background: url("../images/carousel/xagone_carousel-01.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel2 {
      background: url("../images/carousel/xagone_carousel-02.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel3 {
      background: url("../images/carousel/xagone_carousel-03.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel4 {
      background: url("../images/carousel/xagone_carousel-04.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel5 {
      background: url("../images/carousel/xagone_carousel-05.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel6 {
      background: url("../images/carousel/xagone_carousel-06.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel7 {
      background: url("../images/carousel/xagone_carousel-07.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel8 {
      background: url("../images/carousel/xagone_carousel-08.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel9 {
      background: url("../images/carousel/xagone_carousel-09.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel10 {
      background: url("../images/carousel/xagone_carousel-10.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel11 {
      background: url("../images/carousel/xagone_carousel-11.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel12 {
      background: url("../images/carousel/xagone_carousel-12.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel13 {
      background: url("../images/carousel/xagone_carousel-13.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel14 {
      background: url("../images/carousel/xagone_carousel-14.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel15 {
      background: url("../images/carousel/xagone_carousel-15.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel16 {
      background: url("../images/carousel/xagone_carousel-16.jpg") no-repeat center;
      background-size: cover; }
    .content .slickWrapper .slick .carousel17 {
      background: url("../images/carousel/xagone_carousel-17.jpg") no-repeat center;
      background-size: cover; }

.content .contact {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  padding-left: 13.5%;
  padding-right: 13.5%; }
  .content .contact .text {
    position: relative;
    width: 100%;
    background: #ffffff;
    font-family: "Akkurat-Regular", sans-serif; }
    @media screen and (max-width: 768px) {
      .content .contact .text {
        margin: 0 auto;
        /*padding: 40px 20px 100px;*/ } }
    .content .contact .text h2 {
      margin-bottom: 40px; }
      @media screen and (max-width: 1024px) {
        .content .contact .text h2 {
          margin-bottom: 30px; } }
      @media screen and (max-width: 768px) {
        .content .contact .text h2 {
          margin-bottom: 20px; } }
    .content .contact .text h3 {
      color: #1f273b;
      text-transform: none;
      margin: 0;
      max-width: none;
      padding-bottom: 0; }

.content .hexaBtn {
  position: relative;
  font: 17px/1 "Akkurat-Regular", sans-serif;
  color: #1f273b;
  padding-left: 50px;
  letter-spacing: 0.02em; }
  .content .hexaBtn:hover div:after {
    opacity: 1; }
  .content .hexaBtn:hover div:before {
    opacity: 1; }
  .content .hexaBtn div {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
    .content .hexaBtn div:before, .content .hexaBtn div:after {
      content: '';
      position: absolute; }
    .content .hexaBtn div:before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("../images/hexagone.svg") no-repeat center/contain;
      opacity: 0.2;
      transition: opacity 300ms; }
    .content .hexaBtn div:after {
      width: 4px;
      height: 4px;
      top: 50%;
      left: 50%;
      background: #1f273b;
      border-radius: 50%;
      opacity: 0.2;
      transition: width 300ms, height 300ms, opacity 300ms;
      transform: translate(-50%, -50%); }

.content .hexaBtnBlanc {
  position: relative;
  font: 17px/1 "Akkurat-Regular", sans-serif;
  color: #fff;
  padding-left: 50px;
  letter-spacing: 0.02em; }
  .content .hexaBtnBlanc:hover div:after {
    opacity: 1; }
  .content .hexaBtnBlanc:hover div:before {
    opacity: 1;
    background-color: transparent; }
  .content .hexaBtnBlanc div {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 0;
    transform: translateY(-50%); }
    .content .hexaBtnBlanc div:before, .content .hexaBtnBlanc div:after {
      content: '';
      position: absolute; }
    .content .hexaBtnBlanc div:before {
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url("../images/hexagoneBlanc.svg") no-repeat center/contain;
      opacity: 0.2;
      transition: opacity 300ms; }
    .content .hexaBtnBlanc div:after {
      width: 4px;
      height: 4px;
      top: 50%;
      left: 50%;
      background: #fff;
      border-radius: 50%;
      opacity: 0.2;
      transition: width 300ms, height 300ms, opacity 300ms;
      transform: translate(-50%, -50%); }

.content li {
  margin-bottom: 25px;
  letter-spacing: 0.075em; }

#formulaire, #form, #popupInfolettre {
  width: 100%;
  margin: 0; }
  #formulaire fieldset:first-of-type, #form fieldset:first-of-type, #popupInfolettre fieldset:first-of-type {
    margin-top: 0; }
  #formulaire fieldset:last-of-type, #form fieldset:last-of-type, #popupInfolettre fieldset:last-of-type {
    margin-bottom: 0; }
  #formulaire .input-field, #form .input-field, #popupInfolettre .input-field {
    position: relative;
    float: left;
    width: 50%;
    margin-bottom: 60px;
    padding: 0 40px 0 0; }
    @media screen and (max-width: 768px) {
      #formulaire .input-field, #form .input-field, #popupInfolettre .input-field {
        padding: 0;
        width: 100%; } }
  #formulaire .input-field input, #form .input-field input, #popupInfolettre .input-field input {
    width: 100%;
    height: 45px;
    border: none;
    border-bottom: solid 1px #1f273b;
    padding: 0;
    color: #1f273b;
    transition: 250ms ease-out; }
  #formulaire .input-field label, #form .input-field label, #popupInfolettre .input-field label {
    position: absolute;
    left: 0;
    top: 15px;
    color: #1f273b;
    cursor: text;
    transition: 250ms ease-out; }
  #formulaire .input-field input.error + label,
  #formulaire .input-field input.valid + label,
  #formulaire .input-field input:focus + label, #form .input-field input.error + label,
  #form .input-field input.valid + label,
  #form .input-field input:focus + label, #popupInfolettre .input-field input.error + label,
  #popupInfolettre .input-field input.valid + label,
  #popupInfolettre .input-field input:focus + label {
    font-size: 11px;
    top: -12px;
    line-height: 12px;
    cursor: default;
    color: #bd663a;
    pointer-events: none; }
  #formulaire .input-field textarea.valid + label,
  #formulaire .input-field textarea:focus + label, #form .input-field textarea.valid + label,
  #form .input-field textarea:focus + label, #popupInfolettre .input-field textarea.valid + label,
  #popupInfolettre .input-field textarea:focus + label {
    font-size: 11px;
    top: -12px;
    height: 90px;
    line-height: 12px;
    cursor: default;
    color: #5ec3ae;
    pointer-events: none; }
  #formulaire fieldset div.error, #form fieldset div.error, #popupInfolettre fieldset div.error {
    position: absolute;
    left: 40px;
    top: 100%;
    padding-top: 7px;
    color: #db4335; }
  #formulaire .message, #form .message, #popupInfolettre .message {
    width: 100% !important; }
  #formulaire #submitForm, #form #submitForm, #popupInfolettre #submitForm {
    color: #1f273b; }
